<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
    </b-alert>

    <b-row>
      <b-col>
        <user-edit-profil
          v-if="userData"
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { useRouter } from "@core/utils/utils";
import { BAlert, BRow, BCol } from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import store from "@/store";
import UserEditProfil from "./UserEditProfil.vue";

export default {
  components: {
    BAlert,
    BRow,
    BCol,

    UserEditProfil,
  },
  mixins: [PageBoxed],
  setup() {
    const userData = ref(null);

    const { route } = useRouter();

    store
      .dispatch("auth/fetchUserByID", { id: route.value.params.id })
      .then((response) => {
        userData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      userData,
    };
  },
};
</script>

<style></style>
