
















































































































































import Vue from "vue";

// Typescript Files
import * as Api from "@/axios-generated/backoffice/index";

import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BButton,
  BFormRadioGroup,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import { required } from "@validations";
import { resolveAvatarLink } from "@core/utils/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default Vue.extend({
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BButton,
    BFormRadioGroup,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      publicPath: process.env.BASE_URL,
      selectedCompany: null,
      fields: ["entreprise", "code"],
      genderOptions: [
        { value: Api.UserGenderEnum.Male, text: "Homme" },
        { value: Api.UserGenderEnum.Female, text: "Femme" },
        { value: Api.UserGenderEnum.Other, text: "Autre" },
      ],
    };
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      resolveAvatarLink,
    };
  },
  methods: {
    submitForm() {
      const partialUser = {
        first_name: this.userData.first_name,
        last_name: this.userData.last_name,
        gender: this.userData.gender,
        mobile: this.userData.mobile,
      };

      this.$store
        .dispatch("auth/updatePartialUser", {
          id: this.userData.ID,
          partialUser,
        })
        .then(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Utilisateur édité",
                icon: "UserCheckIcon",
                variant: "success",
                text: "Le profil a été mis à jour.",
              },
            },
            { position: "top-center" }
          );
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Edition impossible",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: error.message,
              },
            },
            { position: "top-center" }
          );
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            this.submitForm();
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
});
